import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';

import { Container, NavLink } from './styles';

const LanguageSwitcher = ({ className }) => {
  const { languages, language, originalPath } = useI18next();

  return (
    <Container className={className}>
      {languages.map((lng) => (
        <NavLink key={lng} to={originalPath} language={lng} $isActive={language === lng}>
          {lng}
        </NavLink>
      ))}
    </Container>
  );
};

LanguageSwitcher.defaultProps = {
  className: '',
};

LanguageSwitcher.propTypes = {
  className: PropTypes.string,
};

export default LanguageSwitcher;
