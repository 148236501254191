import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from './styles';

const Button = ({ children, width, type, onClick, className }) => (
  <StyledButton type={type} width={width} onClick={onClick} className={className}>
    {children}
  </StyledButton>
);

Button.defaultProps = {
  type: 'submit',
  width: 'auto',
  onClick: () => null,
  className: '',
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  width: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Button;
