import React from 'react';
import PropTypes from 'prop-types';

import { P } from './styles';

const Message = ({ error, children, noMargin, className }) => (
  <P error={error} noMargin={noMargin} className={className}>
    {children}
  </P>
);

Message.defaultProps = {
  error: false,
  noMargin: false,
  className: null,
};

Message.propTypes = {
  error: PropTypes.bool,
  noMargin: PropTypes.bool,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Message;
