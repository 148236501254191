import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMobileDetect } from '@/utils/hooks';

import GlobalStyle from './GlobalStyles';
import Helmet from './Helmet';
import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';
import { Container, Main } from './styles';

const Layout = ({ children, location, data }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const isMobile = useMobileDetect();

  if (location.pathname.includes('offline-plugin-app-shell-fallback')) return null;
  if (!data.locales) return <Main>{children}</Main>;

  return (
    <>
      <GlobalStyle />
      <Helmet pathname={location.pathname} />
      <Container>
        <Header isOpenMenu={isOpenMenu} toggleMenu={setIsOpenMenu} />
        <Menu isOpenMenu={isOpenMenu} toggleMenu={setIsOpenMenu} />
        <Main isOpenMenu={isOpenMenu}>{(!isMobile && isOpenMenu) || children}</Main>
        <Footer />
      </Container>
    </>
  );
};

Layout.defaultProps = { children: null, data: {} };
Layout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]),
  data: PropTypes.shape({
    locales: PropTypes.shape({}),
  }),
};

export default Layout;
