import React, { useCallback, useEffect } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';

import MedignitionIcon from '@/assets/svg/MedignitionIcon';
import Icon from '@/components/AnimateCross';

import { Container, Wrapper, IconWrapper, Panel, StyledLink, LangBox, MenuButton, ButtonTitle } from './styles';

const Header = ({ isOpenMenu, toggleMenu }) => {
  const { t, originalPath } = useI18next();
  const handleClick = useCallback(() => toggleMenu(($) => !$), [toggleMenu]);

  useEffect(() => {
    toggleMenu(false);
  }, [toggleMenu, originalPath]);

  return (
    <Container>
      <Wrapper>
        <IconWrapper to="/" aria-label="medignition">
          <MedignitionIcon />
        </IconWrapper>
        <Panel>
          <StyledLink to="/contact/investors/" $isMobileVisible={originalPath === '/'} aria-label={t('investors')}>
            {t('investors')}
          </StyledLink>
          <StyledLink to="/contact/partners/" $isMobileVisible={originalPath === '/'} aria-label={t('partners')}>
            {t('partners')}
          </StyledLink>
          <LangBox />
          <MenuButton id="menuButton" onClick={handleClick} aria-label={t('menu')}>
            <ButtonTitle>{t('menu')}</ButtonTitle>
            <Icon isOpen={isOpenMenu} />
          </MenuButton>
        </Panel>
      </Wrapper>
    </Container>
  );
};

Header.propTypes = {
  isOpenMenu: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default Header;
