import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import DefHelmet from 'react-helmet';
import PropTypes from 'prop-types';

import { useExtraMetaData } from './hooks';

function Helmet({ pathname }) {
  const { site, file } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        file(relativePath: { eq: "medigniton.image.png" }) {
          publicURL
        }
      }
    `
  );
  const { title, description: defDescription, author: defAuthor } = site.siteMetadata;
  const { subTitle, description, image, type, author, date, tags, section } = useExtraMetaData();
  const baseUrl = process.env.GATSBY_URL;
  const imageUrl = image?.publicURL || file?.publicURL;
  const mediaTitle = useMemo(() => (subTitle ? `${title} | ${subTitle}` : title), [subTitle, title]);

  return (
    <DefHelmet htmlAttributes={{ lang: 'en' }} defer={false}>
      <title>{mediaTitle}</title>
      <meta name="title" content={mediaTitle} />
      <meta name="description" content={description || defDescription} />
      {type === 'article' && author && <meta property="article:author" content={author} />}
      {type === 'article' && date && <meta property="article:published_time" content={date} />}
      {type === 'article' && date && <meta property="article:modified_time" content={date} />}
      {type === 'article' && section && <meta property="article:section" content={section} />}
      {type === 'article' && Boolean(tags?.length) && tags.map((tag) => <meta key={tag} property="article:tag" content={tag} />)}
      <meta property="og:title" content={mediaTitle} />
      <meta property="og:description" content={description || defDescription} />
      <meta property="og:url" content={baseUrl + pathname} />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content={title} />
      {imageUrl && <meta property="og:image" content={baseUrl + imageUrl} />}
      <meta property="og:image:alt" content="medignition Logo" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={defAuthor} />
      <meta name="twitter:site" content={baseUrl} />
      <meta name="twitter:title" content={mediaTitle} />
      <meta name="twitter:description" content={description || defDescription} />
      {imageUrl && <meta name="twitter:image" content={baseUrl + imageUrl} />}
      <link rel="canonical" href={baseUrl + pathname} />
    </DefHelmet>
  );
}

Helmet.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Helmet;
