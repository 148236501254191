import styled from 'styled-components';

export const StyledButton = styled.button`
  font: var(--font-19);
  height: 40px;
  border: none;
  border-radius: 20px;
  background-color: var(--color-black);
  padding: 0 15px 4px 15px;
  color: var(--color-white);
  width: ${({ width }) => width || 'auto'};
  transition: background-color 0.5s;
  &:focus {
    outline: 0;
  }
  &:active {
    outline: 0;
    background-color: var(--color-black);
  }
  @media (hover) {
    &:hover:enabled {
      cursor: pointer;
      background-color: var(--color-grey-37);
    }
  }
  @media (max-width: 1023px) {
    height: 32px;
    border-radius: 16px;
    font: var(--font-16);
    letter-spacing: 0.59px;
    padding-bottom: 2px;
  }
`;
