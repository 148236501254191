import React, { useMemo, useCallback, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';

import { postContact } from '@/server-api';
import { useAsyncState } from '@/utils/hooks';
import SendIcon from '@/assets/svg/SendIcon';

import validationSchema from './validationSchema';
import { Container, InputContainer, TextInput, Title, Button, MobileBtn, Message } from './styles';

const Newsletter = ({ type }) => {
  const [email, setEmail] = useState('');
  const [alerts, setAlerts] = useAsyncState({});
  const { t } = useI18next();
  const id = useMemo(() => `newsletter_${type}_input`, [type]);

  const handleChange = useCallback(
    (e) => {
      if (alerts.error || alerts.success) {
        setAlerts({});
      }
      setEmail(e.target.value);
    },
    [alerts.error, alerts.success, setAlerts]
  );

  const postData = useCallback(
    (data) => {
      postContact(data)
        .then(() => {
          setAlerts({ success: t('success') });
          setEmail('');
        })
        .catch(() => {
          setAlerts({ error: t('error') });
        });
    },
    [setAlerts, t]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (alerts.loading) return;

      setAlerts({ loading: true });
      validationSchema
        .validate({ email })
        .then(postData)
        .catch((errors) => {
          const [key] = errors.errors;
          setAlerts({ error: t(key) });
        });
    },
    [alerts.loading, email, postData, setAlerts, t]
  );

  const alertMessage = alerts.error || alerts.success;

  return (
    <Container>
      <Title htmlFor={id}>{t('newsletter')}</Title>
      <form onSubmit={handleSubmit}>
        <InputContainer>
          <TextInput id={id} type="email" placeholder={t('emailPlaceholder')} value={email} onChange={handleChange} />
          <MobileBtn aria-label="submit">
            <SendIcon />
          </MobileBtn>
          <Button>{t('subscribe')}</Button>
        </InputContainer>
        {alertMessage && <Message error={Boolean(alerts.error)}>{alertMessage}</Message>}
      </form>
    </Container>
  );
};

Newsletter.propTypes = { type: PropTypes.string.isRequired };

export default Newsletter;
