import styled from 'styled-components';
import { Link } from 'gatsby-plugin-react-i18next';

export const Container = styled.footer`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color-black);
  padding-top: 5px;
  margin-top: 70px;
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const Column = styled.ul`
  margin: 0;
  padding: 0;
`;

export const Title = styled.li`
  list-style: none;
  font: var(--font-14);
  letter-spacing: 0.5px;
  margin-bottom: 15px;
`;

export const LinkItem = styled(Link)`
  font: var(--font-19);
  transition: color 0.5s;
  color: var(--color-black);
  text-decoration: none;
  @media (hover) {
    &:hover {
      color: var(--color-blur);
    }
  }
  @media (max-width: 1023px) {
    font: var(--font-16);
    letter-spacing: 0.59px;
  }
`;

export const ExternalLinkItem = styled.a`
  font: var(--font-19);
  color: var(--color-black);
  text-decoration: none;
  transition: color 0.5s;
  @media (hover) {
    &:hover {
      color: var(--color-blur);
    }
  }
  @media (max-width: 1023px) {
    font: var(--font-16);
    letter-spacing: 0.59px;
  }
`;

export const Item = styled.li`
  font: var(--font-19);
  list-style: none;
  @media (max-width: 1023px) {
    font: var(--font-16);
    letter-spacing: 0.59px;
  }
`;
