import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@/assets/svg/Cross';

import { Button } from './styles';

const AnimateCross = ({ isOpen, onClick, className }) => (
  <Button
    aria-label="Open/Close icon"
    onClick={onClick}
    className={className}
    animate={{ rotate: isOpen ? 45 : 0 }}
    initial={{ rotate: 0 }}
  >
    <Icon />
  </Button>
);

AnimateCross.defaultProps = {
  isOpen: false,
  onClick: () => null,
  className: '',
};

AnimateCross.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default AnimateCross;
