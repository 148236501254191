import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'gatsby-plugin-react-i18next';

import DefAccordion from '@/components/Accordion';

export const getAnimationProps = (isOpen) => ({
  animate: isOpen ? { y: '0%', opacity: 1 } : { y: '-150%', opacity: 0 },
  transition: isOpen ? { type: 'spring', bounce: 0.15, duration: 0.25 } : { duration: 0 },
  initial: { y: '-150%', opacity: 0 },
});

export const MenuContainer = styled(motion.nav)`
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99;
  padding: 0 85px;
  background-color: var(--color-white);
  max-width: 1270px;
  height: 700px;
  margin: 0 auto;
  opacity: 0;
  box-sizing: border-box;
  @media (max-width: 1023px) {
    position: fixed;
    top: 60px;
    height: calc(100% - 60px);
    padding: 0 25px;
    overflow: auto;
  }
  @media (min-width: 1270px) {
    width: 100%;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  @media (max-width: 1023px) {
    display: ${({ $desktopOnly }) => ($desktopOnly ? 'none' : 'flex')};
    height: 70px;
    border-top: 1px solid var(--color-black);
  }
`;

export const Item = styled(Link)`
  width: 100%;
  border-bottom: 3px solid var(--color-black);
  font-size: 120px;
  line-height: 124px;
  text-decoration: none;
  color: ${({ $selected }) => ($selected ? 'var(--color-grey-c1)' : 'var(--color-black)')};
  transition: color 0.5s;
  &:after {
    content: '${({ $content }) => $content}';
  }
  @media (hover) {
    &:hover {
      color: ${({ $selected }) => ($selected ? 'var(--color-grey-c1)' : 'var(--color-blur)')};
    }
  }
  @media (max-width: 1259px) {
    &:after {
      content: '${({ $smallContent, $content }) => $smallContent || $content}';
    }
  }
  @media (max-width: 1050px) {
    font-size: 115px;
  }
  @media (max-width: 1023px) {
    font-size: 44px;
    line-height: 62px;
    border-bottom: none;
  }
  @media (max-width: 375px) {
    font-size: 40px;
  }
`;

export const StyledLink = styled(Link)`
  display: block;
  font: var(--font-19);
  color: ${({ selected }) => (selected ? 'var(--color-grey-c1)' : 'var(--color-black)')};
  text-decoration: none;
  margin-bottom: 12px;
  transition: color 0.5s;
  @media (hover) {
    &:hover {
      color: ${({ selected }) => (selected ? 'var(--color-grey-c1)' : 'var(--color-blur)')};
    }
  }
  @media (max-width: 1023px) {
    font: var(--font-16);
    letter-spacing: 0.59px;
  }
`;

export const Accordion = styled(DefAccordion)`
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`;

export const BottomContainer = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: block;
    margin-top: 10px;
  }
`;

export const FootWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CopyRight = styled.div`
  font: var(--font-19);
  padding: 20px 0;
  @media (max-width: 1023px) {
    font: var(--font-16);
    letter-spacing: 0.59px;
  }
`;

export const Title = styled.p`
  font-size: 44px;
  margin: 0 0 6px 0;
  @media (max-width: 375px) {
    font-size: 40px;
    margin-bottom: 2px;
  }
`;
