import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import Newsletter from '@/components/Newsletter';

import { Container, Column, Title, Item, LinkItem, ExternalLinkItem } from './styles';

const Footer = () => {
  const { t } = useI18next();

  return (
    <Container>
      <Column>
        <Title>{t('copyright')}</Title>
        <Item>{`© ${new Date().getFullYear()}`}</Item>
        <Item>{t('medignition')}</Item>
      </Column>
      <Column>
        <Title>{t('sitemap')}</Title>
        <Item>
          <LinkItem to="/">{t('home')}</LinkItem>
        </Item>
        <Item>
          <LinkItem to="/what-we-do/">{t('what')}</LinkItem>
        </Item>
        <Item>
          <LinkItem to="/how-we-work/">{t('how')}</LinkItem>
        </Item>
        <Item>
          <LinkItem to="/who-we-are/">{t('who')}</LinkItem>
        </Item>
        <Item>
          <LinkItem to="/insights/">{t('insights')}</LinkItem>
        </Item>
        <Item>
          <LinkItem to="/contact/">{t('contact')}</LinkItem>
        </Item>
      </Column>
      <Column>
        <Title>{t('Info')}</Title>
        <Item>
          <ExternalLinkItem target="_blank" href="https://www.linkedin.com/company/medignition/" rel="noopener noreferrer">
            linkedin
          </ExternalLinkItem>
        </Item>
        <Item>
          <ExternalLinkItem target="_blank" href="https://twitter.com/medignition" rel="noopener noreferrer">
            twitter
          </ExternalLinkItem>
        </Item>
        <Item>
          <LinkItem to="/imprint/">{t('imprint')}</LinkItem>
        </Item>
        <Item>
          <LinkItem to="/terms/">{t('terms')}</LinkItem>
        </Item>
        <Item>
          <LinkItem to="/privacy/">{t('privacy')}</LinkItem>
        </Item>
      </Column>
      <Newsletter type="footer" />
    </Container>
  );
};

export default Footer;
