export const MENU_ITEMS = [
  {
    title: 'whatWeDo',
    link: '/what-we-do/',
  },
  {
    title: 'howWeWork',
    link: '/how-we-work/',
  },
  {
    title: 'whoWeAre',
    link: '/who-we-are/',
  },
  {
    title: 'medignition insights',
    smallTitle: 'insights',
    link: '/insights/',
  },
  {
    title: 'contact',
    link: '/contact/',
    desktopOnly: true,
  },
];

export const INFO_ITEMS = [
  {
    title: 'imprint',
    link: '/imprint/',
  },
  {
    title: 'privacy',
    link: '/privacy/',
  },
  {
    title: 'terms',
    link: '/terms/',
  },
];
