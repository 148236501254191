import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  background-color: var(--color-white);
  padding: 0 85px 40px;
  box-sizing: border-box;
  max-width: 1440px;
  min-width: 350px;
  margin: auto;
  transition: opacity 0.25s;
  @media (max-width: 1023px) {
    padding: 0 25px 25px;
  }
`;

export const Main = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: ${({ isOpenMenu }) => (isOpenMenu ? '700px' : 'auto')};
  transition: min-height 0.4s;
  @media (max-width: 1023px) {
    min-height: auto;
  }
`;
