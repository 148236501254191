import React, { useCallback, useEffect, useRef } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';

import { useMobileDetect } from '@/utils/hooks';
import LanguageSwitcher from '@/components/LanguageSwitcher';
import Newsletter from '@/components/Newsletter';

import { MENU_ITEMS, INFO_ITEMS } from './consts';
import {
  MenuContainer,
  ItemContainer,
  Item,
  StyledLink,
  Accordion,
  BottomContainer,
  CopyRight,
  FootWrapper,
  Title,
  getAnimationProps,
} from './styles';

const Menu = ({ isOpenMenu, toggleMenu }) => {
  const ref = useRef();
  const { t, originalPath } = useI18next();
  const contactPageLinks = t('contactPageLinks', { returnObjects: true });
  const isMobile = useMobileDetect();
  const checkClick = useCallback(
    (e) => {
      const elem = e.target;

      if (elem.tagName === 'A' && elem.selected) {
        toggleMenu(false);
      }
      if (elem.closest('#menuButton') || elem.closest('#menuBody')) return;

      toggleMenu(false);
    },
    [toggleMenu]
  );

  useEffect(() => {
    if (isOpenMenu) return () => null;

    window.document.body.addEventListener('click', checkClick);

    return () => window.document.body.removeEventListener('click', checkClick);
  }, [checkClick, isOpenMenu]);

  useEffect(() => {
    if (isOpenMenu && isMobile && ref.current?.scrollTop) ref.current.scroll(0, 0);
    if (!isOpenMenu || isMobile || typeof window === 'undefined' || !window.document.documentElement.scrollTop) return;

    window.scroll(0, 0);
  }, [isMobile, isOpenMenu]);

  return (
    <MenuContainer ref={ref} id="menuBody" {...getAnimationProps(isOpenMenu)}>
      <div>
        {MENU_ITEMS.map(({ title, smallTitle, link, desktopOnly }) => (
          <ItemContainer key={link} $desktopOnly={desktopOnly}>
            <Item
              to={link}
              $selected={originalPath.includes(link)}
              $content={t(title)}
              $smallContent={t(smallTitle)}
              aria-label={t(title)}
            />
          </ItemContainer>
        ))}
        <Accordion title={<Title>{t('contact')}</Title>}>
          {contactPageLinks.map(({ title, link }) => (
            <StyledLink key={link} to={link} selected={originalPath === link} aria-label={title}>
              {title}
            </StyledLink>
          ))}
        </Accordion>
        <Accordion title={<Title>{t('info')}</Title>}>
          {INFO_ITEMS.map(({ title, link }) => (
            <StyledLink key={link} to={link} selected={originalPath === link} aria-label={t(title)}>
              {t(title)}
            </StyledLink>
          ))}
        </Accordion>
      </div>
      <BottomContainer>
        <Newsletter type="menu" />
        <FootWrapper>
          <CopyRight>
            {`© ${new Date().getFullYear()}`} {t('medignition')}
          </CopyRight>
          <LanguageSwitcher />
        </FootWrapper>
      </BottomContainer>
    </MenuContainer>
  );
};

Menu.propTypes = {
  isOpenMenu: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default Menu;
