import styled from 'styled-components';

import Btn from '@/components/Button';
import DefMessage from '@/components/Message';

export const Container = styled.div`
  width: 415px;
  margin: 0;
  padding: 0;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1023px) {
    flex-direction: row;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-black);
  }
`;

export const TextInput = styled.input`
  font: var(--font-19);
  border: none;
  border-bottom: 1px solid var(--color-black);
  width: 100%;
  margin-bottom: 10px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: var(--color-grey-cf);
  }
  @media (max-width: 1023px) {
    flex: 1 1 auto;
    font: var(--font-16);
    letter-spacing: 0.59px;
    margin-bottom: 0;
    border-bottom: none;
  }
`;

export const Title = styled.label`
  display: block;
  font: var(--font-14);
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  @media (max-width: 1023px) {
    font: var(--font-14-bold);
  }
`;

export const Button = styled(Btn)`
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const MobileBtn = styled.button`
  display: none;
  @media (max-width: 1023px) {
    display: inline-block;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    cursor: pointer;
  }
`;

export const Message = styled(DefMessage)`
  @media (max-width: 1023px) {
    margin: 0;
  }
`;
