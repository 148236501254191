import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  width: 100%;
  border-top: 1px solid var(--color-black);
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${({ align }) => align};
  width: 100%;
  padding: ${({ padding }) => padding};
  min-height: 70px;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  font: var(--font-53);
  padding-bottom: 4px;
  @media (max-width: 1023px) {
    font: var(--font-31);
    line-height: 1.23;
  }
`;

export const Content = styled(motion.div)`
  overflow: hidden;
`;
