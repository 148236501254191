import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

export const useExtraMetaData = () => {
  const { t, originalPath } = useI18next();
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(filter: {relativePath: {regex: "/\\.insights_seo_image\\.(png|jpg|jpeg)$/"}}) {
          nodes {
            publicURL
            relativePath
          }
        }
      }
    `
  );
  const metaData = t('metaData', { returnObjects: true });
  const imageObject = useMemo(
    () =>
      allFile.nodes.reduce((acc, image) => {
        acc[image.relativePath] = image;

        return acc;
      }, {}),
    [allFile.nodes]
  );

  return useMemo(() => {
    const { title, description, image, type, author, date, tags, section } =
      (originalPath.includes('/search/') ? metaData['/search/*'] : metaData[originalPath]) || metaData['/'];

    return {
      subTitle: title,
      description,
      image: imageObject[image],
      type: type || 'website',
      author,
      date,
      tags,
      section,
    };
  }, [imageObject, metaData, originalPath]);
};
