function createRequest(data) {
  return {
    headers: {
      'Content-Type': 'application/json',
      'api-key': process.env.GATSBY_API_TOKEN,
    },
    method: 'POST',
    body: JSON.stringify(data),
  };
}

function executeRequest(resource, data, res, rej) {
  fetch(`${process.env.GATSBY_API_URL}/${resource}`, createRequest(data))
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        return response.json().then(({ error }) => rej(new Error(error)));
      }

      return res(response.json());
    })
    .catch(() => rej(new Error('Request failed')));
}

export const postContactRequest = (data) =>
  new Promise((res, rej) => {
    executeRequest('contactrequests', data, res, rej);
  });

export const postContact = (data) =>
  new Promise((res, rej) => {
    executeRequest('contacts', data, res, rej);
  });
