import styled from 'styled-components';
import { Link } from 'gatsby-plugin-react-i18next';

export const Container = styled.div`
  display: flex;
  column-gap: 15px;
  @media (max-width: 1023px) {
    column-gap: 30px;
  }
`;

export const NavLink = styled(Link)`
  font: var(--font-19);
  color: ${({ $isActive }) => ($isActive ? 'var(--color-grey-76)' : 'var(--color-black)')};
  text-decoration: none;
  @media (max-width: 1023px) {
    text-transform: uppercase;
    font: var(--font-21);
  }
`;
