import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Button = styled(motion.button)`
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 1;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
`;
