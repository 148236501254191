import styled from 'styled-components';

export const P = styled.p`
  font: var(--font-19);
  color: ${({ error }) => (error ? 'var(--color-error)' : 'var(--color-success)')};
  margin: ${({ noMargin }) => (noMargin ? 0 : '')};
  @media (max-width: 1023px) {
    font: var(--font-16);
    letter-spacing: 0.59px;
  }
`;
