import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export const useWidthApp = (onlyMobileDetect) => {
  const [width, setWidth] = useState(typeof window !== 'undefined' && window.innerWidth);
  const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' && window.innerWidth <= 1023);

  const handleResize = useCallback(
    (e) => {
      setIsMobile(e.target.innerWidth <= 1023);

      if (onlyMobileDetect) return;

      setWidth(e.target.innerWidth);
    },
    [onlyMobileDetect]
  );

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (window) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [handleResize]);

  return { width, isMobile };
};

export const useMobileDetect = () => useWidthApp(true).isMobile;

export const useAsyncState = (init) => {
  const isMount = useRef();
  const [state, setState] = useState(init);

  const handleChange = useCallback((value) => {
    if (!isMount.current) return;

    setState(value);
  }, []);

  useEffect(() => {
    isMount.current = true;

    return () => {
      isMount.current = false;
    };
  }, []);

  return [state, handleChange, isMount];
};

export const useWhatWeDoMedia = () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/\\.media\\.(png|jpg|jpeg)$/"}}) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              transformOptions: {cropFocus: CENTER, fit: COVER}
            )
          }
          name
          extension
        }
      }
    }
  `);

  return useMemo(
    () =>
      allFile.nodes.reduce((acc, { childImageSharp, name, extension }) => {
        const { gatsbyImageData } = childImageSharp;
        const originalName = [name, extension].join('.');

        acc[originalName] = { ...gatsbyImageData, originalName };

        return acc;
      }, {}),
    [allFile.nodes]
  );
};
