import { createGlobalStyle } from 'styled-components';

import neueHaasUnicaProRegularWoff from '@/assets/fonts/NeueHaasUnicaProRegular.woff';
import neueHaasUnicaProRegularWoff2 from '@/assets/fonts/NeueHaasUnicaProRegular.woff2';
import neueHaasUnicaProBoldWoff from '@/assets/fonts/NeueHaasUnicaProBold.woff';
import neueHaasUnicaProBoldWoff2 from '@/assets/fonts/NeueHaasUnicaProBold.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: "NeueHaasUnicaPro-Regular";
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
    src: url(${neueHaasUnicaProRegularWoff}) format("woff"), url(${neueHaasUnicaProRegularWoff2}) format("woff2");
  }
  @font-face {
    font-family: "NeueHaasUnicaPro-Bold";
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
    src: url(${neueHaasUnicaProBoldWoff}) format("woff"), url(${neueHaasUnicaProBoldWoff2}) format("woff2");
  }
  html {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    font: var(--font-31);
    color: var(--color-black);
    background-color: var(--color-white);
    @media (max-width: 1023px) {
      font: var(--font-21);
    }
  }
  #___gatsby, #gatsby-focus-wrapper  {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  input, textarea {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding-left: 0;
  }
  * {
    -webkit-tap-highlight-color: transparent;
  }
  :root {
    --color-white: #fff;
    --color-black: #000;

    --color-grey-cf: #cfcfcf;
    --color-grey-c1: #c1c1c1;
    --color-grey-c5: #c5c5c5;
    --color-grey-f5: #f5f5f5;
    --color-grey-37: #373737;
    --color-grey-76: #767676;
    --color-grey-97: #979797;

    --color-blur: #0060fb;
    --color-blur-hover: #65a0ff;
    --color-blur-active: #0048bc;
    --color-blur-visited: #0042ad;

    --color-success: #3fd0ab;
    --color-error: #f56f4c;

    --font-79: 79px/1.11 NeueHaasUnicaPro-Regular;
    --font-53: 53px/1.17 NeueHaasUnicaPro-Regular;
    --font-31: 31px/1.26 NeueHaasUnicaPro-Regular;
    --font-21: 21px/1.29 NeueHaasUnicaPro-Regular;
    --font-21-bold: 21px/1.29 NeueHaasUnicaPro-Bold;
    --font-19: 19px/1.37 NeueHaasUnicaPro-Regular;
    --font-19-bold: 19px/1.37 NeueHaasUnicaPro-Bold;
    --font-16: 16.5px/1.33 NeueHaasUnicaPro-Regular;
    --font-16-bold: 16.5px/1.33 NeueHaasUnicaPro-Bold;
    --font-14: 14px/1.36 NeueHaasUnicaPro-Regular;
    --font-14-bold: 14px/1.36 NeueHaasUnicaPro-Bold;
  }
`;
