import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Icon from '@/components/AnimateCross';

import { Container, Title, TitleWrapper, Content } from './styles';

const Accordion = ({ title, children, className, align, padding }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <Container className={className}>
      <Title onClick={handleClick} align={align} padding={padding}>
        <TitleWrapper>{title}</TitleWrapper>
        <Icon isOpen={isOpen} />
      </Title>
      <Content
        animate={{ height: isOpen ? 'auto' : 0, display: isOpen ? 'block' : 'none' }}
        transition={{ height: { duration: 0.3 }, display: { delay: isOpen ? 0 : 0.3 } }}
        initial={{ height: 0, display: 0 }}
      >
        {children}
      </Content>
    </Container>
  );
};

Accordion.defaultProps = {
  className: '',
  align: 'center',
  padding: '0',
};

Accordion.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  className: PropTypes.string,
  align: PropTypes.string,
  padding: PropTypes.string,
};

export default Accordion;
