import styled from 'styled-components';
import { Link } from 'gatsby-plugin-react-i18next';

import LanguageSwitcher from '@/components/LanguageSwitcher';

export const Container = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 90px;
  background-color: var(--color-white);
  z-index: 100;
  @media (max-width: 1023px) {
    height: 60px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding-top: 20px;
  @media (max-width: 1023px) {
    padding-top: 10px;
  }
`;

export const IconWrapper = styled(Link)`
  width: 160px;
  height: 40px;
  padding-top: 8px;
  @media (max-width: 1023px) {
    width: 115px;
    height: 25px;
  }
`;

export const Panel = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  background-color: var(--color-white);
  @media (max-width: 1023px) {
    flex: 1 1 auto;
    justify-content: space-around;
    height: 33px;
    margin: 0 40px 0 25px;
  }
`;

export const StyledLink = styled(Link)`
  font: var(--font-19);
  color: var(--color-black);
  text-decoration: none;
  transition: color 0.5s;

  &:first-child {
    margin-right: 40px;
  }
  @media (hover) {
    &:hover {
      color: var(--color-blur);
    }
  }
  @media (max-width: 1023px) {
    display: ${({ $isMobileVisible }) => ($isMobileVisible ? 'flex' : 'none')};
    font: var(--font-16);
    letter-spacing: 0.59px;
    &:first-child {
      margin-right: 0px;
    }
  }
  @media (max-width: 450px) {
    display: none;
  }
`;

export const LangBox = styled(LanguageSwitcher)`
  margin: 0 120px;
  height: 25px;
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const MenuButton = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 0;
  cursor: pointer;
  line-height: 1;
  @media (max-width: 1023px) {
    position: absolute;
    top: 16px;
    right: 0;
  }
`;

export const ButtonTitle = styled.div`
  font: var(--font-31);
  line-height: 1;
  margin-right: 15px;
  user-select: none;
  @media (max-width: 1023px) {
    display: none;
  }
`;
